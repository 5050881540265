import React, { useState, useEffect } from 'react';
import css from './BannerCliqueFarma.module.scss';

const BannerCliqueFarma = () => {
  const [link, setLink] = useState('https://www.cliquefarma.com.br/');

  useEffect(() => {
    const updateLink = () => {
      if (window.innerWidth <= 768) {
        setLink(
          'https://www.cliquefarma.com.br/?utm_source=agendar-consulta&utm_medium=referral&utm_campaign=agendar-consulta-geral_site_cliquefarma_comparador_agendar-consulta_consideracao_trafego&utm_term=visitantes_br_interesses_display_site&utm_content=2024_banner-home&parceiro_id=460',
        );
      } else {
        setLink(
          'https://www.cliquefarma.com.br/?utm_source=agendar-consulta&utm_medium=referral&utm_campaign=agendar-consulta-geral_site_cliquefarma_comparador_agendar-consulta_consideracao_trafego&utm_term=visitantes_br_interesses_display_site&utm_content=2024_banner-home&parceiro_id=460',
        );
      }
    };

    updateLink();
    window.addEventListener('resize', updateLink);

    return () => window.removeEventListener('resize', updateLink);
  }, []);

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div className={css.banner} />
    </a>
  );
};

export default BannerCliqueFarma;
