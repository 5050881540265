import React from 'react';
import Head from 'next/head';

const title = 'Agendar Consulta - Agende com os melhores médicos da sua cidade';

function HeadHome() {
  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Ache um médico qualificado para cuidar da sua saúde! Veja o perfil completo do médico para ver quais convênios aceita e onde atende. Agende uma consulta online"
      />
    </Head>
  );
}

export default HeadHome;
