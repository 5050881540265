import { connect } from 'react-redux';

import { handleSubmitLogin } from 'app/state/auth/actions';
import {
  fetchResultSearch,
  setDataSearch,
  fetchUserLocation,
} from 'app/state/search/actions';

import Home from '../components/Home';

const mapDispatchToProps = (dispatch) => ({
  login: (params) => dispatch(handleSubmitLogin(params)),
  setDataSearch: (data) => dispatch(setDataSearch(data)),
  getSearchResult: ({
    autocomplete,
    insurance,
    city,
    accepts_in_person,
    accepts_telemedicine,
  }) =>
    dispatch(
      fetchResultSearch({
        autocomplete,
        insurance,
        city,
        accepts_in_person,
        accepts_telemedicine,
      }),
    ),
  generateLink: (label, value) => {
    const params = { [label]: value };
    const queryString = Object.keys(params).map(
      (key) => `${key}=${params[key]}`,
    );
    return `/buscar/${queryString}`;
  },
  getLocation: () => {
    const { geolocation } = navigator;
    if (geolocation) {
      geolocation.getCurrentPosition(({ coords }) => {
        const { latitude, longitude } = coords;
        dispatch(fetchUserLocation({ latitude, longitude }));
      });
    }
  },
});

export default connect(
  (state) => ({
    ...state.search,
  }),
  mapDispatchToProps,
)(Home);
