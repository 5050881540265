export default [
  {
    image: {
      alt: 'Relógio',
      path: 'static/images/clock.png',
    },
    title: 'Agendamento disponível 24h por dia',
    describe: 'Agende sua consulta a qualquer hora e de qualquer lugar, independente de horários comerciais.',
  },
  {
    title: 'Agendamento de consultas simples e prático',
    image: {
      alt: 'Celular',
      path: 'static/images/touch.png',
    },
    describe: 'Sabemos que é difícil agendar um atendimento pelo seu convênio. Encontre um especialista com poucos cliques',
  },
  {
    image: {
      alt: 'Notificação',
      path: 'static/images/notification.png',
    },
    title: 'Agende no horário ideal pra você',
    describe: 'Busque por diversos especialistas ao mesmo tempo e agende uma consulta no melhor horário para você.',
  },
];
