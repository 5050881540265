import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RowBoot from 'react-bootstrap/Row';
import ColBoot from 'react-bootstrap/Col';
import ContainerBoot from 'react-bootstrap/Container';

import { fetchLastSchedules } from 'app/state/schedule/actions';
import {
  CardProfile,
  DragScroller,
} from 'app/components';

import css from './Queries.module.scss';

export default function LastSchedules() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLastSchedules());
  }, [fetchLastSchedules]);
  const { lastSchedule } = useSelector((state) => state.schedule);

  return lastSchedule.length > 0 && (
  <ContainerBoot className={css.queries}>
    <section>
      <RowBoot>
        <ColBoot lg={4} md={12} sm={12}>
          <h2>Últimas consultas marcadas</h2>
        </ColBoot>
        <ColBoot className={css['queries-content']} lg={8} md={12} sm={12}>
          <DragScroller>
            {lastSchedule && lastSchedule.map((schedule, index) => (
              <CardProfile
                key={index}
                cbo={schedule.cbo}
                location={schedule.location}
                time={schedule.time}
              />
            ))}
          </DragScroller>
        </ColBoot>
      </RowBoot>
    </section>
  </ContainerBoot>
  );
}
